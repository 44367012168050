import { getUserDeviceKeys } from '@canalplus/mycanal-commons';
import { ApiV2TvodPayment } from '@dce-front/hodor-types';
import { ApiV2TvodPaymentError } from '@dce-front/hodor-types/api/v2/tvod/payment/definitions';
import {
  ApiV2PaymentMeansDetail,
  ApiV2PaymentMeansPurchase,
} from '@dce-front/hodor-types/api/v2/tvod/payment_means/definitions';
import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { XOR } from 'ts-essentials';
import { redirectPaymentApi } from '../../../../../../api/SaleApi/SaleApi';
import { CookieKey } from '../../../../../../constants/cookie';
import { MutationKeys } from '../../../../../../constants/mutationKeys';
import { useAppDispatch } from '../../../../../../helpers/hooks/useAppDispatch';
import {
  getFeatureToggleCBDeleteNSave,
  offerLocationSelector,
  tokenCMSSelector,
} from '../../../../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../../../../store/slices/displayMode-selectors';
import { PurchaseCodeState } from '../../../../../../store/slices/purchaseCode';
import { analyticsIdSelector } from '../../../../../../store/slices/user-selectors';
import { FunnelHodorStep, FunnelInnerStep } from '../../../../../../templates/FunnelTvod/stores/constants';
import {
  incrementFunnelHistory,
  setCurrentStep,
  setErrorTemplate,
  setIsLoading,
} from '../../../../../../templates/FunnelTvod/stores/funnel/actions';
import {
  useFunnelCurrentStep,
  useFunnelDispatch,
  useFunnelPaymentMean,
} from '../../../../../../templates/FunnelTvod/stores/funnel/hooks';

/**
 * useRedirectPayment hook that redirect to payment page with URLWebsite from hodor
 */
export const useRedirectPayment = (): ((
  purchase: ApiV2PaymentMeansPurchase,
  detail: ApiV2PaymentMeansDetail,
  purchaseInfo: PurchaseCodeState['purchaseInfo']
) => Promise<void>) => {
  const dispatch = useAppDispatch();
  const paymentMean = useFunnelPaymentMean();
  const funnelDispatch = useFunnelDispatch();
  const tokenCMS = useSelector(tokenCMSSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const saveOption = useSelector(getFeatureToggleCBDeleteNSave);
  const offerLocation = useSelector(offerLocationSelector);
  const analyticsId = useSelector(analyticsIdSelector);
  const queryClient = useQueryClient();
  const currentStep = useFunnelCurrentStep();
  const userDeviceCookie = Cookies.get(CookieKey.DeviceId);
  const { trackingKey, deviceId } = getUserDeviceKeys(userDeviceCookie);

  return useCallback(
    async (purchase, detail, purchaseInfo) => {
      const handle = redirectPaymentApi({
        purchase,
        detail,
        paymentMean,
        tokenCMS,
        isTvDevice,
        saveOption,
        offerLocation,
        funnelDispatch,
        userDeviceKeys: { trackingKey, deviceId, analyticsId },
        purchaseInfo: {
          productPrice: purchaseInfo?.productPrice?.toString(),
          productCategory: purchaseInfo?.productCategory,
        },
      });

      if (isTvDevice) {
        queryClient.setMutationDefaults([MutationKeys.RedirectPayment], {
          mutationFn: () => handle(dispatch),
        });
        // Deported Payment for oD
        const newStep = {
          ...currentStep,
          innerStep: FunnelInnerStep.DeportedPayment,
        };
        funnelDispatch(setCurrentStep(newStep));
        funnelDispatch(incrementFunnelHistory(newStep));
      }
      // Redirect payment for Desktop
      else {
        funnelDispatch(setIsLoading(true));
        const response = (await handle(dispatch)) as XOR<ApiV2TvodPayment, ApiV2TvodPaymentError>;

        funnelDispatch(setIsLoading(false));
        // Hodor respond with paymentError template
        if (response?.currentPage?.displayTemplate === FunnelHodorStep.PaymentError) {
          funnelDispatch(setErrorTemplate(response));
        } else if (response?.redirectTo) {
          // Hodor respond with redirectTo
          const { redirectTo } = response;
          // RedirectTo desktop
          if (redirectTo && redirectTo.URLWebsite) {
            window.location.href = redirectTo.URLWebsite;
          }
        } else {
          // Hodor KO or displayTemplate === error
          funnelDispatch(
            setErrorTemplate({
              currentPage: {
                displayTemplate: FunnelHodorStep.PaymentError,
              },
            })
          );
        }
      }
    },
    [
      paymentMean,
      tokenCMS,
      isTvDevice,
      saveOption,
      offerLocation,
      funnelDispatch,
      trackingKey,
      deviceId,
      analyticsId,
      queryClient,
      currentStep,
      dispatch,
    ]
  );
};
