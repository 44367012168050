import { fillTemplate } from '@canalplus/mycanal-commons';
import { useSelector } from 'react-redux';
import { appKeySelector, tokenCMSSelector } from '../../../store/slices/application-selectors';
import { getPublicConfig } from '../../config/config-helper';

/** Get **Terms of Sale** final URL */
export function useGetTermsOfSaleUrl(): string {
  const appKey = useSelector(appKeySelector);
  const cmsToken = useSelector(tokenCMSSelector);

  const {
    baseUrl,
    paths: { termsOfSale },
  } = getPublicConfig().api.hodor;

  return fillTemplate(baseUrl + termsOfSale, [
    ['appKey', appKey],
    ['cmsToken', cmsToken],
  ]);
}
