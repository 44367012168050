import { Link } from '@canalplus/dive';
import { useSelector } from 'react-redux';
import { isTim } from '../../../../../../helpers/application/application-helper';
import { getPublicConfig } from '../../../../../../helpers/config/config-helper';
import I18n from '../../../../../../lang';
import { displayTVModeSelector } from '../../../../../../store/slices/displayMode-selectors';
import styles from '../LegalInformation.css';

export type LegalItem = {
  name: string;
  label: string | React.ReactElement;
  alertLabel: string;
  type: 'readonly' | 'checkable';
};

/**
 * Hook that returns all readonly and checkable items for legal information.
 * For TIM: CGV is readonly and withdrawal is checkable
 * For Others: CGV and withdrawal are checkable
 * @returns all readonly and checkable items
 */
export const useLegalItems = (): LegalItem[] => {
  const { t } = I18n.useTranslation();
  const cgvLink = getPublicConfig().CGV.link;
  const isTvDevice = useSelector(displayTVModeSelector);

  const labelCGV = isTvDevice ? (
    t('FunnelTvod.agreeTV')
  ) : (
    <>
      {t('FunnelTvod.agree')}{' '}
      <Link href={cgvLink} target="_blank" className={styles.LegalInformation__link}>
        {t('FunnelTvod.terms')}
      </Link>{' '}
    </>
  );

  const cgvItem = {
    name: t('FunnelTvod.cgvName'),
    label: labelCGV,
    alertLabel: t('FunnelTvod.cgvAlert'),
    type: isTim() ? 'readonly' : 'checkable',
  } satisfies LegalItem;

  const withdrawalItem = {
    name: t('FunnelTvod.withdrawalName'),
    label: t('FunnelTvod.withdrawal'),
    alertLabel: t('FunnelTvod.withdrawalAlert'),
    type: 'checkable',
  } satisfies LegalItem;

  return [cgvItem, withdrawalItem];
};
