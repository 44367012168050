import { cva } from 'class-variance-authority';

export const listGroupHeaderCVA = cva('font-hind font-semibold m-0 p-0', {
  variants: {
    device: {
      tv: 'text-42 text-dt-theme-tv-text-list-list-group-title',
      web: 'text-22 text-dt-theme-text-list-row-list-title',
    },
  },
});

export const listGroupItemsCVA = cva(undefined, {
  variants: {
    device: {
      tv: '',
      web: [
        'relative',
        'after:bg-dt-theme-border-list-row-divider after:absolute after:-bottom-1 after:left-16 after:right-16 after:content-[""] after:z-10', // Inside a group we need to have a little borders between elements which does not take the entire width
      ],
    },
    first: {
      true: '',
      false: '',
    },
    last: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      device: 'web',
      first: true,
      className: 'rounded-t-dt-radius-small',
    },
    {
      device: 'web',
      first: false,
      className: 'rounded-t-dt-radius-none',
    },
    {
      device: 'web',
      last: true,
      className: 'rounded-b-dt-radius-small',
    },
    {
      device: 'web',
      last: false,
      className: 'rounded-b-dt-radius-none border-b-dt-border-none after:h-1',
    },
    {
      device: 'tv',
      last: false,
      className: 'mb-dt-spacing-250',
    },
  ],
});
