import { isClientSide } from '@canalplus/mycanal-commons';
import { getSigninUrl } from '@canalplus/sdk-pass';
import { usePassSdk } from '@canalplus/sdk-pass-react';
import { R7Context } from 'r7extlib';
import { useSelector } from 'react-redux';
import { isR7Available } from '../../../../../../helpers/window/window-helper';
import { offerLocationSelector, offerZoneSelector } from '../../../../../../store/slices/application-selectors';
import type { HandleStepParams } from '../../ContextualOffer';

/**
 * Hook that redirectTo to account and redirect to urlPage from template PaymentMeans
 * @returns {Function} onClick
 */
export const useRedirectProspect = (): ((onClick: HandleStepParams['onClick']) => void) => {
  const signinUrl = usePassSdk(getSigninUrl);
  const offerLocation = useSelector(offerLocationSelector);
  const offerZone = useSelector(offerZoneSelector);

  return ({ path }: HandleStepParams['onClick'] = {}) => {
    const redirectUri = isClientSide() ? `${window.location.origin}${path}` : '';
    if (isR7Available()) {
      // add setContext with the path to redirect in deeplink
      window.R7('setContext', {
        context: {
          deeplink: path,
        } as R7Context,
      });
      window.R7('loginUser', {
        offerZone,
        offerLocation,
      });
      return;
    }

    window.location.assign(signinUrl({ redirectUri }));
  };
};
